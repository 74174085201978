<template>
    <div class="privacy-policy">
        <div class="header">
            <router-link :to="{ name: 'chat' }" class="home-link">
                <img src="/static/unstruct-logo-new.png" alt="UnStruct Logo" width="55" height="55" />
            </router-link>
            <h1>UnStruct's Privacy Policy</h1>
        </div>
      <p class="last-updated">Last updated: October 30, 2024</p>

      <p>At UnStructAI, Inc ("we", "us", "our"), we are committed to protecting the privacy and security of our users' information. This policy outlines our practices regarding data collection, use, and sharing.</p>

      <h2>Anonymous Usage</h2>
      <p>UnStruct.AI is designed with privacy-first principles. You can use our service completely anonymously:</p>
      <ul>
        <li>No email or personal information required to start using the service</li>
        <li>Anonymous users can access all features, including Pro subscriptions</li>
        <li>Your anonymous access is controlled by a secure key that:</li>
        <ul>
          <li>Is randomly generated and stored in your browser's local storage</li>
          <li>Can be saved and reused across different browsers/devices</li>
          <li>Functions like a password for your anonymous account</li>
        </ul>
        <li>No tracking or analytics for anonymous sessions</li>
        <li>No personal data is collected or stored</li>
      </ul>
      <p>Important note about anonymous access:</p>
      <ul>
        <li>Your access key is your responsibility to maintain</li>
        <li>We cannot recover lost anonymous keys</li>
        <li>Clearing browser data without saving your key will result in permanent loss of:</li>
        <ul>
          <li>Access to your account</li>
          <li>Access to Pro features</li>
          <li>Usage history</li>
        </ul>
        <li>If you need persistent access management, consider creating a registered account</li>
      </ul>

      <h2>Scope</h2>
      <p>This Privacy Policy applies to our website (www.unstruct.ai), applications, services, tools, and features (collectively, the "Services"). By using any of the Services, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>

      <h2>Changes to This Policy</h2>
      <p>We may update this privacy policy from time to time. If we make material changes, we will notify you through a notice on the Services prior to the change becoming effective. For registered users, we'll also send an email notification.</p>

      <h2>Information We Collect</h2>

      <h3>Anonymous Users</h3>
      <p>For users choosing anonymous access:</p>
      <ul>
        <li>A randomly generated session key (stored only in your browser)</li>
        <li>Payment information if upgrading to our Pro plan (processed by Stripe, not linked to personal data)</li>
        <li>User Inputs and AI-Generated Outputs (not linked to any personal identifiers)</li>
      </ul>

      <h3>Registered Users</h3>
      <ol>
        <li><strong>Account Information:</strong> When you create an account, we collect only your email address.</li>
        <li><strong>Google OAuth Login:</strong> When you choose to log in with Google, we request access to your email address and basic profile information (name, profile picture).</li>
        <li><strong>Payment Information:</strong> If you make a purchase, we collect payment details, which are processed by our third-party payment processor.</li>
        <li><strong>User Inputs and AI-Generated Outputs:</strong> We collect and process the data, queries, prompts, and any other content that you input, upload, or submit to our AI-powered platform ("User Inputs"). We also store the results, insights, and any other content generated by our AI in response to your inputs ("AI-Generated Outputs"). Please note that depending on the nature of your User Inputs, they may contain personal information. We use this information to:
          <ul>
            <li>Generate AI-Generated Outputs as part of our core Services</li>
            <li>Improve and refine our AI models and algorithms</li>
            <li>Analyze usage patterns to enhance our Services</li>
            <li>Ensure compliance with our Terms of Service</li>
          </ul>
        </li>
      </ol>

      <h3>Information from Third Parties</h3>
      <p>Currently, we do not collect or obtain any information about you from third-party sources. If this changes in the future, we will update this privacy policy and notify users accordingly.</p>

      <h2>How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide, maintain, and improve our Services</li>
        <li>Process payments and fulfill orders</li>
        <li>Communicate with you about our Services (only if registered)</li>
        <li>Analyze usage patterns and trends</li>
        <li>Protect the safety and security of our Services and users</li>
        <li>Comply with legal obligations</li>
      </ul>

      <h2>Data Sharing and Disclosure</h2>
      <p>For anonymous users, we only share:</p>
      <ul>
          <li>Payment information with our payment processor (Stripe)</li>
          <li>Information required by law enforcement (if legally compelled)</li>
      </ul>
      <p>For registered users, we may additionally share information with:</p>
      <ul>
        <li>Service providers who help us provide the Services</li>
        <li>Affiliates within our corporate group</li>
        <li>Third parties to whom you request or direct us to disclose information</li>
        <li>Professional advisors (e.g., lawyers, accountants)</li>
        <li>Parties involved in a business transaction (e.g., merger, acquisition)</li>
        <li>Law enforcement or regulatory agencies, as required by law</li>
      </ul>
      <p>We do not sell your personal information.</p>

      <h2>Data Security and Retention</h2>
      <p>We implement appropriate technical and organizational measures to protect your personal information. However, no security measures are 100% secure, and we cannot guarantee the security of your information.</p>
      <p>We retain your information for as long as necessary to provide the Services and fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.</p>
      <p>To protect your privacy and manage our resources efficiently, we implement the following data retention and cleanup processes:</p>
      <ul>
        <li>Extracted text from file, image, text, and YouTube transcript submissions older than 30 days will be automatically deleted from our systems.</li>
        <li>The binary content in these files is automatically deleted within a few hours after extraction.</li>
        <li>Content crawled from websites will be automatically deleted after 7 days.</li>
        <li>This cleanup process occurs periodically and automatically.</li>
      </ul>
      <p>If you need to retain specific data for a longer period, please contact our support team.</p>
      <p>If you delete your account, we will delete your personal information from our servers within 30 days. Please contact us at support@unstruct.ai to request deletion.</p>

      <h3>Anonymous Data Handling</h3>
      <p>For anonymous users:</p>
      <ul>
        <li>Session data is identified only by hashed anonymous keys</li>
        <li>No IP addresses or device information or location is stored</li>
        <li>Payment information is handled separately through Stripe</li>
        <li>Session data follows the same retention policies as registered users</li>
      </ul>

      <h2>Your Rights and Choices</h2>

      <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
      <ul>
        <li>The right to access and receive a copy of your data</li>
        <li>The right to correct or update your data</li>
        <li>The right to delete your data</li>
        <li>The right to object to or restrict processing of your data</li>
      </ul>
      <p>To exercise these rights, please contact us at support@unstruct.ai.</p>

      <h2>Children's Privacy</h2>
      <p>Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.</p>

      <h2>California Residents</h2>
      <p>California residents may have additional rights under the California Consumer Privacy Act (CCPA). Please contact us for more information about your California privacy rights.</p>

      <h2>Contact Information</h2>
      <p>For any questions or concerns regarding our privacy practices, please contact us at support@unstruct.ai.</p>
    </div>
  </template>

  <script>
  export default {
    name: 'PrivacyPolicy',
  }
  </script>

<style scoped>
.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }

.header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.home-link {
    text-decoration: none;
    margin-right: 20px;
    }

  h1 {
    font-size: 2.5em;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
  }

  h2 {
    font-size: 1.8em;
    margin-top: 30px;
  }

  h3 {
    font-size: 1.4em;
  }

  p, ul, ol {
    margin-bottom: 15px;
  }

  ul, ol {
    padding-left: 30px;
  }

  .last-updated {
    font-style: italic;
    color: #7f8c8d;
  }

  a {
    color: #3498db;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
</style>
